/* Styles go here */
.title {
    color:rgb(0, 0, 0);
    text-align: center;
}

.titleem {
    color:rgb(243, 49, 243);
}

.title2 {
    color:rgb(0, 0, 0);
    size: 0px;
    text-align: center;
}

.logo {
    padding-bottom: 10px;
}

.loading {
    font-style: italic;
    position: fixed;
    font-size: 40px;
    
    color: rgb(8, 4, 4);
    top: calc(50% - 4em);
    margin-left: 300px;
    
    height: 6em;
}


.loader {
    position: absolute;
    top: calc(50% - 4em);
    left: calc(50% - 4em);
    width: 6em;
    height: 6em;
    color: rgb(226, 113, 226);;
  }

.connect {
    color:rgb(226, 113, 226);
    font-size: large;
    font-weight: bold;
    
}

.account {
    color: white;
    
}

.right {
    position: relative;
    margin-left: 260px;

}

.left {
    text-align: left;
}

.padbtm {

    padding-bottom: 25px;
}

.padtp {

    margin-top: 25px;
}
.width {

    width:50%;
    text-align: center;
    padding:5px;
    

}



.center {

    width:50%;
    text-align: center;
    
}
.container {
    height: 20px;
    position: relative;
    border: none;
  }
  
  .center1 {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }



div.parentElement{text-align: center;}

.btn-work {
    width: 250px;
    height: 50px;
    margin: 0 auto;
    padding: 0;
    display: inline-block;
    line-height: 50px;
    text-align: center;
}

.number {
    color: rgb(243, 49, 243);
    -webkit-text-emphasis: bold;
            text-emphasis: bold;
 

}

.danger {
    color: white;
    font-size: large;
    font-weight: bold
}

.large
{
    position: relative;
    height: 40px;
    width: 40px;
}

.warning {
    color:red;
}

.maint {
    color:green;
}

.color {
    color:rgb(255, 255, 255);
    background-color: #bf0e51;

}

.color1 {
    color:rgb(255, 255, 255);
    background-color: #1a0ec7;

}

.color2 {
    color:rgb(138, 11, 11);
font-family: Arial, Helvetica, sans-serif;
font-size: large;

}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
